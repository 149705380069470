<template>
  <div class="home">
    <el-container style=" border: 1px solid #eee ;border-top-left-radius: 15px;height: 100vh ;">
      <el-aside width="220px"  v-show="WebNav" :style="asideStyle" style="height:calc(100vh - 1px);">
        <el-menu
            class="el-menu-vertical-demo"
            router
            unique-opened:true
            collapse-transition:true

        >
          <div style="z-index: 100">
            <img src="../assets/logo2.png"
                 style="height: 60px;width: 215px;border-top-left-radius: 15px; "
                 @click="NumberCount">
          </div>
<!--          <div style="height:60px"></div>-->
          <el-submenu :index="index+''" v-for="(item,index) in routes" v-if="!item.hidden" :key="index">
            <template slot="title">
              <i style="color: #409eff;margin-right: 5px" :class="item.iconCls"></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item :index="child.path" v-for="(child,indexj) in item.children" :key="indexj"
                          @click="hideAside()">
              {{ child.name }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="homeHeader">
          <el-breadcrumb class="el-breadcrumb-separator" >
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{this.$router.currentRoute.name}}</el-breadcrumb-item>
          </el-breadcrumb>
          <div>
            <!--            <el-button icon="el-icon-bell" type="text" style="margin-right: 8px;color: #000000;" size="normal" @click="goChat"></el-button>-->
            <el-dropdown class="userInfo" @command="commandHandler" style="position: absolute; right: 70px;top: 30px">
  <span class="el-dropdown-link">
    <div class="el-icon-user" style="margin-right: 7px"></div> {{ user.user }}
  </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="userinfo" disabled>个人中心</el-dropdown-item>
                <el-dropdown-item command="setting">设置</el-dropdown-item>
                <el-dropdown-item command="logout" divided>注销登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div style="margin-bottom: 1%;padding-bottom: 2%">
            <span v-show="isMobile" @click="MobileNavShow() ">
              <i class="el-icon-open" v-show="open"></i><i class="el-icon-turn-off" v-show="off"></i>
            </span>
          </div>

        </el-header>

        <el-main>

          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

  </div>
</template>


<script>


export default {
  name: 'Home',
  data() {
    return {
      open: false,
      off: true,
      isMobile: false,
      WebNav: true,
      asideStyle: {
        position: '', // 固定定位
      }
      // power: JSON.parse(window.sessionStorage.getItem("power"))
    }
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile.bind(this));
  }
  ,
  computed: {
    routes() {
      return this.$store.state.routes;
    },
    user() {
      return this.$store.state.currentHr;
    }
  },
  methods: {
    hideAside() {
      if (this.isMobile) {
        this.WebNav = false;
      }

    },
    MobileNavShow() {
      if (this.WebNav) {
        this.WebNav = false;
        this.off = true;
        this.open = false;
        this.asideStyle.position = "";
      } else {
        this.WebNav = true;
        this.open = true;
        this.off = false;
        this.asideStyle.position = "fixed";
      }

    },
    checkMobile() {
      // 设置一个阈值，比如768px，小于这个值就认为是移动设备
      const MOBILE_WIDTH_THRESHOLD = 768;
      this.isMobile = window.innerWidth <= MOBILE_WIDTH_THRESHOLD;
      if (this.isMobile) {
        this.WebNav = false;
      }else{
        this.WebNav = true;
      }
    },
    NumberCount() {
      if (this.$router.currentRoute.fullPath !== "/home") {
        this.$router.replace({path: '/home'})
      }
    },
    commandHandler(cmd) {
      if (cmd === 'logout') {
        this.$confirm('此操作将注销登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/logout");
          window.sessionStorage.removeItem("user")
          this.$store.commit('initRoutes', []);
          this.$router.replace("/");
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      } else if (cmd == 'userinfo') {
        this.$router.push('/hrinfo');
      }
    }
  }
}
</script>
<style>
.el-icon-turn-off {
  font-size: 20px;
}

.el-icon-open {
  font-size: 20px;

}

.el-breadcrumb-separator {
.el-breadcrumb__separator {
  color: black;
}
}

.homeHeader {
  height: 60px;
  background-color:#EBEEF5;;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  box-sizing: border-box;
}

.homeHeader .userInfo {
  cursor: pointer;
}

.el-aside {
  z-index: 100;
  color: #333;
  height: 732px;


}

.el-aside::-webkit-scrollbar {
  display: none;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  position: relative;
  top: -10px;
}

.el-dropdown-link img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-left: 8px;
}

/*.el-menu-vertical-demo::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/
/*/deep/.el-scrollbar__wrap {*/
/*  overflow-x: hidden;*/
/*  overflow-y: hidden;*/
/*}*/
/*/deep/.el-scrollbar__thumb {*/

/*background: transparent;*/
/*}*/

</style>

