import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from "@/views/Home";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        hidden: true
    }
    , {
        path: '/home',
        name: 'Home',
        component: Home,
       // redirect: '/dashboard/largeScreen',
        hidden: true,
        meta: {
            roles: ['admin', 'user']
        },
        children: [

        ]
    }
]

const router = new VueRouter({
   // mode: 'history',
    routes
})

export default router

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
    return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
    return originalReplace.call(this , location).catch(err=>err)
}

